import { AcquiredPayee } from "../../FirestoreConverters"
import Loading from "../General/Loading"
import LinkButton from "../General/LinkButton"
import { useMemo, useState } from "react"
import Modal from "../General/Modal"
import LinkBankAccountForm from "../User/LinkBankAccountForm"
import RedeemForm from "./RedeemForm"
import { Link, useOutletContext } from "react-router-dom"
import { MyAccountProps } from "../Page/MyAccount"
import { useFunctions } from "reactfire"
import { httpsCallable } from "firebase/functions"

const RedeemSelectBankAccount = () => {
  const {event} = useOutletContext<MyAccountProps>()
  const [showAddAccountModal, setShowAddAccountModal] = useState(false)
  const [showRedeemModal, setShowRedeemModal] = useState(false)
  const [payeeToUse, setPayeeToUse] = useState<false | AcquiredPayee>(false)

  const functions = useFunctions()
  const listBankAccounts = httpsCallable<Record<string, never>, AcquiredPayee[]>(functions, "listBankAccounts")

  const [accounts, setAccounts] = useState<false | AcquiredPayee[]>()
  useMemo(() => {
    if(accounts !== undefined) return
    listBankAccounts()
      .then(({data}) => {
        console.log({data})
        setAccounts(data)
      })
      .catch(e => {
        console.log(e)
        setAccounts(false)
      })
  }, [listBankAccounts, accounts])

  if (event === undefined) return <p>No jar could be found</p>
  if (accounts === undefined) return <Loading />
  if (accounts === false) return <p>Failed to load your accounts</p>

  return <>
    {showAddAccountModal === true ?
      <Modal title="Add new Bank Account" closeFunction={() => setShowAddAccountModal(false)}>
        <div className="py-4">
          <LinkBankAccountForm onComplete={() => {
            setAccounts(undefined)
            setShowAddAccountModal(false)
          }}/>
        </div>
      </Modal>
    : null}
    {showRedeemModal === true && payeeToUse !== false?
      <Modal
        title="Confirm Redeem Details"
        closeFunction={() => setShowRedeemModal(false)}
      >
        <RedeemForm payee={payeeToUse} event={event} />
      </Modal>
    : null}
    <p className="text-center">Please choose which account we should redeem your contributions to.</p>
    <div className="flex flex-col max-w-sm mx-auto gap-2 p-4">
      {accounts.length > 0 ? accounts.map(acc => {
          return <button
            className="flex-1 border border-my-grey rounded-sm text-center px-2 py-4 min-w-60"
            onClick={() => {
              setPayeeToUse(acc)
              setShowRedeemModal(true)
            }}
          >
            <span className="font-bold uppercase block">
              {acc.nickname}
            </span>
            <span className="text-sm">Ending: {acc.ending}</span>
          </button>
        })
      :
        <p>
          No accounts found. Please add one below
        </p>
      }
    </div>
    {accounts.length < 2 ?
      <div className="text-center p-2">
        <LinkButton type="button" clickHandler={() => {
          setShowAddAccountModal(true)
        }}>Add new account</LinkButton>
      </div>
    :
      <p className="text-center">You can have a maximum of two linked bank accounts. You can unlink an existing account, and link a new one within the <Link to="/my-account/bank-accounts" className="underline text-my-orange hover:text-my-olive">My Account</Link> section.</p>
    }

    <div className="text-center p-2">
      <LinkButton type="link" url="../">
        Back to jar
      </LinkButton>
    </div>
  </>
}

export default RedeemSelectBankAccount